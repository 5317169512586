
import { defineComponent, ref } from 'vue';
import router from '../router';
import { calculateAge } from '../helpers/dateHelper';

export default defineComponent({
  beforeRouteLeave(to, from, next) {
    this.isLeaving = true;

    next();
  },
  setup() {
    const age = ref(calculateAge('09/08/1990'));
    const gameExp = ref(calculateAge('01/03/2023'));
    const isEntering = ref<boolean>(false);
    const isLeaving = ref<boolean>(false);

    const onClickProject = (projectId: string) => {
      router.push(`/portfolio/${projectId}`);
    };

    return {
      age,
      gameExp,
      isEntering,
      isLeaving,
      onClickProject,
    };
  },
});
