<template>
  <div class="content-wrapper">
    <div class="header">
      <h1>
        <router-link class="header-link" to="/">Tomas Wallin</router-link>
        <small>Gameplay Programmer</small>
      </h1>

      <div class="links">
        <a
          class="link"
          href="https://www.linkedin.com/in/tomwal/"
          target="_blank"
          title="LinkedIn"
        >
          <span class="sr-only">Tomas Wallins LinkedIn account</span>
          <fa class="link-icon" :icon="['fab', 'linkedin']" />
        </a>

        <a
          class="link"
          href="https://github.com/Myggski"
          target="_blank"
          title="Github"
        >
          <span class="sr-only">Tomas Wallins github account</span>
          <fa class="link-icon" :icon="['fab', 'github']" />
        </a>

        <a
          class="link"
          href="https://www.instagram.com/mr.tomaswallin/"
          target="_blank"
          title="Instagram"
        >
          <span class="sr-only">Tomas Wallins instagram account</span>
          <fa class="link-icon" :icon="['fab', 'instagram']" />
        </a>

        <a
          class="link resume"
          :href="require('./assets/tomaswallinresume.pdf')"
          target="_blank"
          title="Resume"
        >
          <span class="sr-only">Tomas Wallins resume</span>
          <span>Resume</span>
          <fa class="link-icon" :icon="['fas', 'arrow-down']" />
        </a>
      </div>
    </div>

    <router-view />
  </div>
</template>

<script>
const DEFAULT_TITLE = 'Tomas Wallin | Gameplay Programmer - ';

export default {
  name: 'App',
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        document.title = DEFAULT_TITLE + to.name || to.path;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.content-wrapper {
  margin: 0 auto;
  @media only screen and (min-width: 1200px) {
    max-width: 1168px;
  }

  .header-link {
    color: $primaryDP0;
    text-decoration: none;
  }

  .header {
    position: sticky;
    top: 0;
    background-color: $dark;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: -16px -16px 16px -16px;
    padding: 16px;

    @media (min-width: 768px) {
      margin-bottom: 256px;
      flex-direction: row;
      padding: 32px 16px 0 16px;
      margin: -32px -16px 16px -16px;
    }

    h1 {
      font-size: 2rem;
      color: $primaryDP1;
      margin: 0 0 16px 0;

      @media (min-width: 768px) {
        font-size: 3rem;
      }

      small {
        margin-top: 4px;
        display: block;
        font-size: 1.125rem;
        font-weight: 400;
      }
    }

    .links {
      display: flex;
      height: 42px;

      .link {
        display: inline-flex;
        flex-direction: column;
        align-items: center;

        font-size: 2.8125rem;
        color: $complementaryDP0;
        margin-right: 16px;

        @media (min-width: 768px) {
          margin-right: 0;
          margin-left: 16px;
        }

        &:hover {
          color: $complementaryDP2;
        }

        &.resume {
          flex-direction: row;
          font-size: 18px;
          text-decoration: none;
          border: solid 2px;
          border-radius: 4px;
          padding: 0 8px;

          .link-icon {
            margin-left: 8px;
          }
        }
      }
    }
  }
}
</style>
