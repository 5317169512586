import {
  faInstagram,
  faGithub,
  faLinkedin,
  faUnity,
} from '@fortawesome/free-brands-svg-icons';
import {
  faArrowDown,
  faUsers,
  faToolbox,
  faClock,
  faUser,
  faCodeBranch,
  faXmark,
  faArrowUpRightFromSquare,
  faChevronRight,
  faChevronLeft,
  faScrewdriverWrench,
} from '@fortawesome/free-solid-svg-icons';
import { library, config, dom } from '@fortawesome/fontawesome-svg-core';
import {
  faSquarePlus,
  faSquareMinus,
} from '@fortawesome/free-regular-svg-icons';

library.add(
  faInstagram,
  faGithub,
  faLinkedin,
  faArrowDown,
  faUsers,
  faToolbox,
  faClock,
  faUser,
  faCodeBranch,
  faXmark,
  faArrowUpRightFromSquare,
  faChevronRight,
  faChevronLeft,
  faSquarePlus,
  faSquareMinus,
  faUnity,
  faScrewdriverWrench,
);

config.searchPseudoElements = true;

dom.watch();
